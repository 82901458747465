<script>
import StandForm from "@/views/menu/stand/standForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    standService: "standService",
    fileUploadService: "fileUploadService",
    visitorLevelService: "visitorLevelService",
    toastService: "toastService",
  },
})
export default class StandClone extends mixins(StandForm) {
  formId = "clone-stand-form";
  idToClone = "";

  get decoratedElements() {
    return this.insertBefore(
      this.elements,
      {
        legend: this.translations.labels.stand_form_itemsToDuplicate,
        type: "fieldset",
        group: "default",
        collapsible: true,
        open: true,
        resetColumns: true,
        elements: [
          {
            id: "hasStandOwner",
            label: this.translations.labels.stand_form_standOwner, //TODO: change label
            type: "checkbox",
            required: false,
          },
          {
            id: "hasInfobox",
            label: this.translations.labels.stand_form_infobox, //TODO: change label
            type: "checkbox",
            required: false,
          },
          {
            id: "hasLink",
            label: this.translations.labels.stand_form_link, //TODO: change label
            type: "checkbox",
            required: false,
          },
          {
            id: "hasFile",
            label: this.translations.labels.stand_form_file, //TODO: change label
            type: "checkbox",
            required: false,
          },
          {
            id: "hasVideo",
            label: this.translations.labels.stand_form_video, //TODO: change label
            type: "checkbox",
            required: false,
          },
          {
            id: "hasHtmlPage",
            label: this.translations.labels.stand_form_htmlPage, //TODO: change label
            type: "checkbox",
            required: false,
          },
          // end Contents
        ]
      },
    );
  }
  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
   async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      this.model.localizations.forEach((loc) => {
        if (!loc.videoPlatformType) {
          loc.videoPlatformType = "Default";
        }
      });
      await this.standService.clone(this.model, this.idToClone);
      this.$router.push("/stand/list");
      this.toastService.success(this.translations.success.stand_clone);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.stands_clone;
  }

  async afterCreate() {
    this.model = await this.standService.read(this.$route.params.id);

    this.setVisitorLevels(
      await this.visitorLevelService.enabledLevels(this.model.id)
    );
    this.model.visitorLevels = this.currentVisitorLevelsValue();

    this.idToClone = this.model.id;
    this.model.id = null;
    this.model.code = null;

    this.isReady = true;
  }

}
</script>
